/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import Media from "react-md/lib/Media/Media";
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from "react-slick";

import Layout from '../../layout';

const settings = {
  dots: false,
  arrows: false,
  fade: true,
  autoplay: true,
  pauseOnHover: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1
};

const CommunityProgram = () => (
  <StaticQuery
    query={graphql`
      query {
        Image1: file(relativePath: { eq: "13.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        Image2: file(relativePath: { eq: "14.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Slider {...settings}>
              <Card raised="true">
                <Media>
                  <Img fluid={data.Image1.childImageSharp.fluid} />
                </Media>
              </Card>
              <Card raised="true">
                <Media>
                  <Img fluid={data.Image2.childImageSharp.fluid} />
                </Media>
              </Card>
            </Slider>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Toddler Program : The Children's Community (18 months and walking to 3 years)" />
              <CardText>
                <p className="md-text-justify">
                  The Toddler program is prepared with the specific needs of that age group in mind. What
                  are its characteristics?
                </p>
                <p className="md-text-justify">
                  It is a very orderly environment, because routine and physical order help the child develop
                  his or her mental order.
                </p>
                <p className="md-text-justify">
                  It is a time and place for the toddlers to acquire skills, gather information about their
                  world, experience a role as a contributing member of a social group, and develop their
                  individual personality through concrete experiences of their day-to-day activities. Teachers
                  work to create a beautiful, structured, and orderly classroom environment.
                </p>
                <p className="md-text-justify">
                  Children in the Toddler House refine their understanding of the world through engaging
                  and enticing interactions, movement, and activities. Teachers carefully select materials and
                  activities based on a toddler’s cognitive, social-emotional, and physical development.
                  These young children are also in a prime developmental period for acquiring language.
                  That is why we expose them to beautiful, precise, and factual language that not only
                  provides names of things, but provides the language to describe qualities, feelings, and
                  emotions.
                </p>
                <h2 className="md-headline">Child Development</h2>
                <p className="md-text-justify">
                  <font className="md-title">Development of Gross Motor Control</font>
                  <br />
                  The young child’s energy is never-ending. Each toddler is actively working on becoming
                  more skilled and coordinated with his or her body. We provide activities that allow them to
                  become more coordinated by walking, balancing, and carrying things around the room or
                  using tools to complete activities.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Development of Fine Motor Skills</font>
                  <br />
                  The young toddler is also exposed to activities that will help develop the fine motor skills.
                  Teachers introduce materials that require a wide range of grasps and hand-eye
                  coordination like placing rings on pegs, sorting objects, exploring object-permanence,
                  stringing beads, completing peg puzzles, exploring with blocks or balls, and even sewing!
                  Materials are beautiful, constructed of natural materials, and rotated frequently.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Development of Independent Functioning and Social Skills</font>
                  <br />
                  The young child’s appetite or independence is infinite. He or she wants to imitate adults
                  and do things for themselves. Teachers demonstrate how to use materials to help children
                  prepare food, care for materials in the classroom, care for themselves, or care for pets and
                  plants. Activities range from something simple like wiping a spill on a table or washing a
                  window to activities that require many steps to complete like creating a flower
                  arrangement or baking bread. At a time when a child’s personality is developing, he or she
                  comes to know, “I am capable, and the things I do are important to the people around me.”
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Development of Concentration</font>
                  <br />
                  All the activities in the Toddler environment appeal to the child’s interests and all for the
                  physical and mental energies to work together. This is when the child is happy, feels
                  content, and is satisfied. Activities must present a challenge to children and be purposeful.
                  Driven by his or her insatiable quest for independence, the mastery of a skill is the child’s
                  unique motivation.
                </p>
                <p className="md-text-justify">
                  <font className="md-title">Development of Language</font>
                  <br />
                  Language is pervasive throughout the environment. There is a focus on guiding children
                  with respectful, courteous language and a perspective of helpfulness. Above all, teachers
                  work together to create a warm, nurturing and safe space for every child. Teachers look for
                  opportunities to support conversation, read poems and books, and introduce fun language
                  games. We present children with large, beautiful pictures and replicas of animals, familiar
                  objects, types of motorized vehicles, etc. and we give them the precise vocabulary attached
                  to them. It is important for children to attach a concrete experience to words at this stage
                  as the young child has such a concrete mind.
                </p>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default CommunityProgram;
